import React, { useState, useEffect } from "react";
import axios from "axios";
import Erreur from "./Erreur";

//componant present in pages /faq and every /template-sous-section
// in /faq page, there is filter menu
//in /template-sous-section there is no filter menu but question are filtered by default by sous section id.

const API_BASE_URL = "https://passionate-book-96c97196c7.strapiapp.com/api";

const FAQItem = ({ sectionId, hideAside = false }) => {
  const [faqs, setFaqs] = useState([]);
  const [filters, setFilters] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [expandedId, setExpandedId] = useState(null);
  const [dropdownExpanded, setDropdownExpanded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  //fetch fonction
  const fetchData = async (url) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      });
      return response.data.data;
    } catch (error) {
      console.error("Erreur lors de la récupération des données:", error);
      return null;
    }
  };

  //fetch data
  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      try {
        const faqsData = await fetchData(
          `${API_BASE_URL}/faqs?populate=*&pagination[pageSize]=50`
        );
        setFaqs(faqsData || []);

        const filtersData = await fetchData(
          `${API_BASE_URL}/faq-sections?populate=*`
        );
        setFilters(filtersData || []);

        const defaultFilter = filtersData.find(
          (filter) => filter.attributes.TitreSection === "Toutes les catégories"
        );
        setSelectedFilter(
          sectionId || (defaultFilter ? defaultFilter.id : "all")
        );
      } catch (error) {
        setError("Une erreur est survenue lors du chargement des données.");
        console.error("Erreur lors de la récupération des données:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, [sectionId]);

  //filter fonction
  const filteredFaqs = faqs.filter((faq) => {
    const sections = faq.attributes.sections?.data || [];

    if (sectionId) {
      const isSectionMatch = Array.isArray(sections)
        ? sections.some((section) => section.id === Number(sectionId))
        : sections.id === Number(sectionId);
      return isSectionMatch;
    }

    const isCategoryMatch =
      selectedFilter === "all" ||
      faq.attributes.faqsections.data.some(
        (section) => section.id === Number(selectedFilter)
      );
    return isCategoryMatch;
  });

  const selectedFilterTitle =
    filters.find((filter) => filter.id === selectedFilter)?.attributes
      .TitreSection || "Filtres";

  //mobile only filter accordion menu fonctions
  const handleAccordionToggle = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };

  const toggleDropdown = () => {
    setDropdownExpanded((prev) => !prev);
  };

  const handleFilterChange = (id) => {
    setSelectedFilter(id);
    if (window.innerWidth <= 768) {
      setDropdownExpanded(false);
    }
  };

  if (loading) return <div></div>;
  if (error)
    return (
      <div>
        <Erreur />
      </div>
    );

  return (
    <div className="faq-item">
      <div className="faq-container">
        {!hideAside && (
          <aside
            className={`filters ${
              window.innerWidth <= 768 && dropdownExpanded ? "expanded" : ""
            }`}
          >
            {window.innerWidth <= 768 ? (
              <>
                <div className="filter-header" onClick={toggleDropdown}>
                  <h4>{selectedFilterTitle}</h4>
                  <span className="chevron">
                    <i
                      className={`fas fa-chevron-${
                        dropdownExpanded ? "up" : "down"
                      }`}
                    ></i>
                  </span>
                </div>
                <div
                  className={`filter-dropdown ${
                    dropdownExpanded ? "expanded" : ""
                  }`}
                >
                  <ul>
                    {filters
                      .sort((a, b) => a.id - b.id)
                      .map((filter) => (
                        <li
                          key={filter.id}
                          onClick={() => handleFilterChange(filter.id)}
                          className={
                            selectedFilter === filter.id ? "active" : ""
                          }
                        >
                          {filter.attributes.TitreSection}
                        </li>
                      ))}
                  </ul>
                </div>
              </>
            ) : (
              <>
                <ul>
                  {filters
                    .sort((a, b) => a.id - b.id)
                    .map((filter) => (
                      <li
                        key={filter.id}
                        onClick={() => handleFilterChange(filter.id)}
                        className={selectedFilter === filter.id ? "active" : ""}
                      >
                        {filter.attributes.TitreSection}
                      </li>
                    ))}
                </ul>
                <a href="/contact" className="contact-link">
                  Une question ? Contactez-nous
                </a>
              </>
            )}
          </aside>
        )}
        <main className="content">
          <h1>Vous vous posez encore des questions ?</h1>
          {filteredFaqs.length === 0 ? (
            <p>Aucune question trouvée pour cette catégorie ou section.</p>
          ) : (
            filteredFaqs.map((faq) => (
              <div
                key={faq.id}
                className={`question ${
                  expandedId === faq.id ? "expanded" : ""
                }`}
              >
                <div className="question-header">
                  <h4
                    className={expandedId === faq.id ? "question-selected" : ""}
                    onClick={() => handleAccordionToggle(faq.id)}
                  >
                    {faq.attributes.Question}
                  </h4>
                  <span
                    className="chevron"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAccordionToggle(faq.id);
                    }}
                  >
                    <i
                      className={`fas fa-chevron-${
                        expandedId === faq.id ? "up" : "down"
                      }`}
                    ></i>
                  </span>
                </div>
                {expandedId === faq.id && (
                  <div className="answer">
                    {/* <p>{faq.attributes.Reponse}</p> */}
                    <p
                      dangerouslySetInnerHTML={{
                        __html: faq.attributes.Reponse.replace(/\n/g, "<br />"),
                      }}
                    ></p>
                  </div>
                )}
              </div>
            ))
          )}
        </main>
      </div>
    </div>
  );
};

export default FAQItem;
